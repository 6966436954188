import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import Glide from '@glidejs/glide';

// import intersect from '@alpinejs/intersect';


window.Alpine = Alpine;
Alpine.plugin(collapse);
// Alpine.plugin(intersect);
document.addEventListener('DOMContentLoaded', function () {
	const glideElement = document.querySelector('.glide');
	const glideElement2 = document.querySelector('.glide2');
	const swipeElement = document.querySelector('.swiper');

	
	if (glideElement) {
		const glide = new Glide(glideElement, {
			type: 'carousel',
			perView: 3,
			breakpoints: {
				768: {
					perView: 1,
				},
			},
			gap: 10,
			animationDuration: 600,
			animationTimingFunc: 'ease-in-out',
			autoplay: 5000,
			hoverpause: true,
		});

		function updateCenterSlide() {
			const slides = document.querySelectorAll('.glide__slide');
			slides.forEach((slide) => slide.classList.remove('center-slide'));

			if (glide.settings.perView === 3) {
				const perViewOffset = Math.floor(glide.settings.perView / 2);
				const totalSlides = slides.length;
				const originalSlides = document.querySelectorAll(
					'.glide__slide:not(.glide__slide--clone)'
				);
				const clonesPerSide = (totalSlides - originalSlides.length) / 2;
				const centerIndex =
					(glide.index + perViewOffset + clonesPerSide) % totalSlides;
				slides[centerIndex].classList.add('center-slide');
			}
		}

		glide.on(['move.after', 'mount.after'], function () {
			updateCenterSlide();
		});

		glide.mount();
	}

	if (glideElement2) {
		const glide2 = new Glide(glideElement2, {
			type: 'carousel',
			perView: 1,
			animationDuration: 600,
			animationTimingFunc: 'ease-in-out',
			autoplay: 13000,
			hoverpause: true,
		});

		glide2.mount();
	
	}
});


window.Alpine = Alpine